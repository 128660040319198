/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { usePrefs, useLabels } from 'wsm-common-data';
import { trackEvent } from '../utils/trackEvent';

const Paragraph = ({ counts, trackGa }) => {
	const {
		paragraphLabel,
		paragraphTextSize,
		paragraphTextAlign,
		paragraphCountFontWeight,
		textColor
	} = usePrefs();
	const labels = useLabels();
	const pLabel = labels.get(paragraphLabel);
	// Builds an array of the count placeholders (anything wrapped in curly brackets) that are found inside paragraphLabel
	const placeholdersArray = pLabel.match(/\{([^}]+)\}/g);
	const allParagraphClasses = `text-content-container ${paragraphTextSize} ${paragraphTextAlign} ${textColor}`;
	const allCountClasses = `${paragraphCountFontWeight} ${textColor}`;
	const markupArray = [];

	const checkRemainingTextAndPush = (index, updatedRemainingParagraph) => {
		if (
			index === placeholdersArray.length - 1 &&
			updatedRemainingParagraph.length > 0
		) {
			markupArray.push(
				<span
					key={`string${index + 1}`}
					dangerouslySetInnerHTML={{
						__html: updatedRemainingParagraph
					}}
				/>
			);
		}
	};

	if (placeholdersArray) {
		// Loop through placeholdersArray and build out array of markup
		placeholdersArray.reduce((remainingParagrah, placeholder, index) => {
			// If we cannot match a count for the placeholder, we fall back to displaying the placeholder itself
			let countMarkup = <span key={`count${index}`}>{placeholder}</span>;

			// Find the count object that matches the placeholder
			const countObject = counts.filter(
				(count) =>
					count.name === placeholder.replace('{', '').replace('}', '')
			)[0];
			if (countObject) {
				const { count, name, link, label } = countObject;

				countMarkup = link ? (
					<a
						key={`count${index}`}
						className={allCountClasses}
						href={link}
						onClick={() => {
							trackEvent(trackGa, name, link, label);
						}}
					>
						{count}
					</a>
				) : (
					<span
						key={`count${index}`}
						className={paragraphCountFontWeight}
					>
						{count}
					</span>
				);
			}
			// Trim the text and count that we are about to push to markupArray
			const updatedRemainingParagraph = remainingParagrah.substring(
				remainingParagrah.indexOf(placeholder) + placeholder.length
			);
			if (remainingParagrah.indexOf(placeholder) !== 0) {
				// If paragraph does not start with a count, push span with text then count
				markupArray.push(
					<span
						key={`string${index}`}
						dangerouslySetInnerHTML={{
							__html: remainingParagrah.substring(
								0,
								remainingParagrah.indexOf(placeholder)
							)
						}}
					/>
				);
				markupArray.push(countMarkup);
				// If we just pushed our last count (on our last loop) and we still have remaining text, push it
				checkRemainingTextAndPush(index, updatedRemainingParagraph);
				return updatedRemainingParagraph;
			} else {
				// If paragraph starts with a count, push that first
				markupArray.push(countMarkup);
				// If we just pushed our last count (on our last loop) and we still have remaining text, push it
				checkRemainingTextAndPush(index, updatedRemainingParagraph);
				return remainingParagrah.substring(placeholder.length);
			}
		}, pLabel);
	}

	return <p className={allParagraphClasses}>{markupArray}</p>;
};

Paragraph.propTypes = {
	counts: PropTypes.arrayOf(
		PropTypes.shape({
			count: PropTypes.string,
			name: PropTypes.string,
			link: PropTypes.string,
			label: PropTypes.string
		})
	).isRequired,
	trackGa: PropTypes.func.isRequired
};

Paragraph.defaultProps = {};

export default Paragraph;
