import { formatNumber } from 'ddc-intl';

/**
 * Function used to floor vehicle counts to the nearest whole
 * number divisible by toNearest and add commas.
 *
 * i.e.	When number = 8,165 and floorTo = 100, the result will be '8,100+'
 *
 * @param number
 * @param floorTo
 * @param locale
 * @returns string containing the count with a suffix(+) if number has been rounded
 */
export const formatCount = (number, floorTo, locale) => {
	if (floorTo > 1) {
		const rNumber = Math.floor(number / floorTo) * floorTo;
		const formattedNum = formatNumber(rNumber, locale);
		return rNumber < number ? `${formattedNum}+` : formattedNum;
	}
	return formatNumber(number, locale);
};
