// This file is intended for global constants that are shared across
// the application. Use this file sparingly. For constants with a single
// usage, it can be better to include them in the module scope of the
// file they're used in.
export const initCounts = {
	NEW_VEHICLES: 0,
	CERTIFIED_VEHICLES: 0,
	USED_VEHICLES: 0,
	ALL_VEHICLES: 0
};
