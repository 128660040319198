import { SET_MORE_REQUEST_DATA } from '../actions/types';

export default function moreRequestData(state = {}, action) {
	if (!action.error && action.payload !== undefined) {
		switch (action.type) {
			case SET_MORE_REQUEST_DATA:
				return action.payload;
			// no default
		}
	}
	return state;
}
