import React from 'react';
import { addNewRelicPageAction } from 'ddc-new-relic';
import { usePrefs } from 'wsm-common-data';
import Counts from '../components/Counts';
import useCounts from '../hooks/useCounts';
import './Widget.scss';
import PlaceholderContainer from '../components/CountsLoader';

const Widget = () => {
	const { counts, isLoading, errorMsg } = useCounts();
	const { displayStyle } = usePrefs();
	if (isLoading) {
		if (displayStyle === 'Paragraph') {
			return null;
		} else {
			return <PlaceholderContainer />;
		}
	}
	if (errorMsg) {
		addNewRelicPageAction('ws-inv-count-display count request failed.', {
			errorMsg
		});
		if (displayStyle === 'Paragraph') {
			// need requested counts for this display style
			return null;
		}
	}
	return <Counts counts={counts} />;
};

export default Widget;
