import React from 'react';
import PropTypes from 'prop-types';
import {
	useLabels,
	useSitemap,
	usePrefs,
	useRequestData
} from 'wsm-common-data';
import CountItem from './CountItem';
import Paragraph from './Paragraph';
import { formatCount } from '../utils/formatCount';

const Counts = ({ counts }) => {
	const sitemap = useSitemap();
	const labels = useLabels();
	const { windowId, locale } = useRequestData();
	const {
		allVehicleLabel,
		allVehicleLink,
		roundVehiclesTo,
		preownedVehicleLabel,
		preownedVehicleLink,
		certifiedVehicleLabel,
		certifiedVehicleLink,
		newVehicleLabel,
		newVehicleLink,
		displayStyle,
		makeLabel,
		makeLink,
		makeCount,
		dealershipLabel,
		dealershipLink,
		dealershipCount,
		showCounts
	} = usePrefs();
	const trackGa = (trackingObj) => {
		const isBrowser = typeof window !== 'undefined';
		if (isBrowser) {
			const DDC = window.DDC || {};
			const defaultTrackingObj = {
				event: 'widgetUserEvent',
				widgetName: 'ws-inv-count-display',
				widgetId: windowId,
				widgetState: 'default',
				element: '',
				action: 'clicked',
				result: '',
				elementCta: ''
			};

			if (DDC.fireTrackingEvent) {
				DDC.fireTrackingEvent({
					...defaultTrackingObj,
					...trackingObj
				});
			}
		}
	};

	/**
	 * Gets the label, hyperlink, and formatted count for each countItem.
	 * If a custom link via preferences cannot be found, they will default
	 * to the default params.
	 *
	 * @param label
	 * @param link
	 * @param count
	 * @param floorTo
	 */
	const getCountData = (label, link, count, floorTo) => {
		const countData = {};
		countData.label = labels.get(label);
		countData.link = sitemap.get(link);
		countData.count = count
			? formatCount(parseInt(count, 10), floorTo, locale)
			: null;
		return countData;
	};

	const displayedCounts = showCounts.split(',');
	// Create array of CountItem components with appropriate values based on key
	const countArray = displayedCounts.reduce((acc, key) => {
		let countData = {};
		switch (key) {
			case 'ALL_VEHICLES':
				countData = getCountData(
					allVehicleLabel,
					allVehicleLink,
					counts && counts[key],
					roundVehiclesTo
				);
				break;
			case 'USED_VEHICLES':
				countData = getCountData(
					preownedVehicleLabel,
					preownedVehicleLink,
					counts && counts[key],
					roundVehiclesTo
				);
				break;
			case 'NEW_VEHICLES':
				countData = getCountData(
					newVehicleLabel,
					newVehicleLink,
					counts && counts[key],
					roundVehiclesTo
				);
				break;
			case 'CERTIFIED_VEHICLES':
				countData = getCountData(
					certifiedVehicleLabel,
					certifiedVehicleLink,
					counts && counts[key],
					roundVehiclesTo
				);
				break;
			case 'MAKES':
				countData = getCountData(makeLabel, makeLink, makeCount, 1);
				break;
			case 'DEALERSHIPS':
			default:
				countData = getCountData(
					dealershipLabel,
					dealershipLink,
					dealershipCount,
					1
				);
		}
		if (displayStyle === 'Paragraph') {
			acc.push({
				name: key,
				count: countData.count,
				link: countData.link
			});
		} else {
			acc.push(
				<CountItem
					key={key}
					className={key}
					label={countData.label}
					count={countData.count}
					link={countData.link}
					trackGa={trackGa}
					name={key}
				/>
			);
		}
		return acc;
	}, []);
	if (displayStyle === 'Paragraph') {
		return <Paragraph counts={countArray} trackGa={trackGa} />;
	} else {
		return (
			<div className="d-flex flex-wrap justify-content-around text-center pt-3 pb-4 m-3">
				{countArray}
			</div>
		);
	}
};

Counts.propTypes = {
	counts: PropTypes.shape({}).isRequired
};

export default Counts;
