import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { usePrefs } from 'wsm-common-data';
import { trackEvent } from '../utils/trackEvent';

const CountItem = ({ className, label, count, link, trackGa, name }) => {
	const { textColor, countTextSize, labelTextSize, animate } = usePrefs();
	const [isHovered, setIsHovered] = useState(false);
	const handleHover = () => {
		setIsHovered(!isHovered);
	};

	if (count === null) {
		return null;
	}

	const containerName = className.toLowerCase().replace(/_/g, '-');
	const itemDisplay = (
		<div className={textColor}>
			<div className={`item-count ${countTextSize}`}>{count}</div>
			<div className={`item-label ddc-font-size-${labelTextSize}`}>
				{label}
			</div>
		</div>
	);
	const utilityClass =
		'transition-property-transform transition-timing-function-standard transition-duration-lg';
	const hoverClass = isHovered
		? `${utilityClass} scale-110`
		: `${utilityClass} scale-100`;
	return count !== '0' ? (
		<div
			className={`count-item p-2 ${
				animate ? `animate ${hoverClass}` : ' '
			} item-${containerName}`}
			onClick={() => {
				trackEvent(trackGa, name, link, label);
			}}
			onMouseEnter={handleHover}
			onMouseLeave={handleHover}
			role="presentation"
		>
			{link ? (
				<a className="item-link text-no-decoration" href={link}>
					{itemDisplay}
				</a>
			) : (
				itemDisplay
			)}
		</div>
	) : null;
};

CountItem.propTypes = {
	label: PropTypes.string.isRequired,
	count: PropTypes.string.isRequired,
	link: PropTypes.string,
	className: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	trackGa: PropTypes.func.isRequired
};

CountItem.defaultProps = {
	link: null
};

export default CountItem;
