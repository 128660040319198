import React from 'react';
import { usePrefs } from 'wsm-common-data';

const PlaceholderContainer = () => {
	const { countTextSize, labelTextSize } = usePrefs();
	return (
		<div data-testid="placeholder-container" className="pt-3 pb-4 m-3">
			<div className={`item-count ${countTextSize}`}>
				<div className="invisible">0</div>
			</div>
			<div className={`mt-3 item-label ddc-font-size-${labelTextSize}`}>
				<div className="invisible">labels</div>
			</div>
		</div>
	);
};

export default PlaceholderContainer;
