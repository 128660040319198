/**
 * Function used to fire widgetUserEvents
 *
 * @param trackGa
 * @param name
 * @param link
 * @param label
 */
export const trackEvent = (trackGa, name, link, label) => {
	trackGa({
		element: name,
		result: `Navigated to ${link}`,
		elementCta: label
	});
};
